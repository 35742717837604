/* Importing Bootstrap SCSS file. */

@import '~ngx-toastr/toastr';
@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');

/* You can add global styles to this file, and also import other style files */

.modal-holder {
    animation-name: example;
    animation-duration: 0.3s;
}

@keyframes example {
    0% {
        transform: scale(0.5)
    }
    100% {
        transform: scale(1)
    }
}

.carousel-item {
    transition: opacity 0.7s ease !important;
    position: absolute !important;
    display: block !important;
    opacity: 0;
}

.carousel-item.active {
    position: relative !important;
    opacity: 1;
}

.card {
    border-radius: 10px!important;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Lexend, sans-serif;
    touch-action: manipulation;
    overflow-x: hidden;
}

.ng-select {
    color: black!important;
}


/* loading.component.scss */

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.spinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid #f3f3f3;
    border-top-color: #3498db;
    animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

input {
    touch-action: manipulation;
}